//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import BlogArticlesGrid from "../common/BlogArticlesGrid.vue";
import Pagination from "../common/Pagination.vue";
import FooterEmail from "../inputs/FooterEmail.vue";
import { mapGetters } from "vuex";
import BlogArticleLinkV2 from '../common/BlogArticleLinkV2.vue';
export default {
  components: { Pagination, BlogArticlesGrid, FooterEmail, BlogArticleLinkV2 },
  name: "blogList",
  props: {
    currentPage: {
      type: Number,
      default: 1,
    },
    totalPage: {
      type: Number,
      default: 1,
    },
    blogItems: {
      type: Array,
      default: () => [],
    },
    page: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      tabs: false,
      currentTab: 1,
      loaded: false
    };
  },
  computed: {
    ...mapGetters(["options"]),
    pagiData() {
      if (this.currentPage) {
        let p = [];
        if (this.currentPage > 1) p.push({ type: "prev", s: true });
        else p.push({ type: "prev", s: false });
        let pre = this.currentPage;
        if (pre > 4) {
          p.push({ type: "page", l: 1 });
          p.push({ type: "dot" });
          for (let i = this.currentPage - 2; i < this.currentPage; i++) {
            p.push({ type: "page", l: i, current: false });
          }
        } else {
          for (let i = 1; i < this.currentPage; i++) {
            p.push({ type: "page", l: i, current: false });
          }
        }
        let post = this.totalPage - this.currentPage;
        if (post > 4) {
          for (let i = this.currentPage; i < this.currentPage + 3; i++) {
            if (i == this.currentPage)
              p.push({ type: "page", l: i, current: true });
            else p.push({ type: "page", l: i, current: false });
          }
          p.push({ type: "dot" });
          p.push({ type: "page", l: this.totalPage });
        } else {
          for (let i = this.currentPage; i <= this.totalPage; i++) {
            if (i == this.currentPage)
              p.push({ type: "page", l: i, current: true });
            else p.push({ type: "page", l: i, current: false });
          }
        }
        if (this.currentPage < this.totalPage)
          p.push({ type: "next", s: true });
        else p.push({ type: "next", s: false });
        return p;
      }
      return null;
    },
  },
};
