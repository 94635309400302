//
//
//
//
//
//
//
//
//
//
//
//
//

import { adapter } from "@/utils.js";
import BlogList from "../components/sections/BlogList.vue";
import AppBreadcrumbs from "../components/navigation/app-breadcrumbs.vue";
export default {
  components: { BlogList, AppBreadcrumbs },
  name: "BlogPage",
  data() {
    return {
      currentPage: 1,
      totalPage: 1,
      blogItems: [],
      page: null,
      breadcrumbs: [],
    };
  },
  watch: {
    "$route.fullPath"() {
      const url = "/api/blog/articles/all";
      const props = {
        lang: this.$i18n.locale,
        page: this.$route.query.page || 1,
      };
      adapter
        .post(url, props, this)
        .then((response) => {
          this.page = response.page;
          this.breadcrumbs = response.breadcrumbs;
          this.blogItems = response.items.models;
          this.currentPage = response.items.paginate.current_page;
          this.totalPage = Math.ceil(
            response.items.paginate.total / response.items.paginate.per_page
          );
        })
        .catch((e) => {
          this.handleError(e);
        });
    },
  },
  created() {
    let url;
    let props;
    if (this.$route.name === "blog") {
      url = "/api/blog/articles/all";
      props = {
        lang: this.$i18n.locale,
        page: this.$route.query.page || 1,
      };
    } else if (this.$route.name === "rubric") {
      url = "/api/blog/articles/get-by-category-slug";
      props = {
        lang: this.$i18n.locale,
        slug: this.$route.params.slug,
      };
    }
    adapter
      .post(url, props, this)
      .then((response) => {
        this.page = response.page;
        this.breadcrumbs = response.breadcrumbs;
        this.blogItems = response.items.models;
        this.currentPage = response.items.paginate.current_page;
        this.totalPage = Math.ceil(
          response.items.paginate.total / response.items.paginate.per_page
        );
      })
      .catch((e) => {
        this.handleError(e);
      });
  },
};
