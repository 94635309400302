//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from "vuex";
import AppLink from "../common/AppLink.vue";
export default {
  components: { AppLink },
  name: "SiteFooter",
  data() {
    return {
      btnPos: "top",
    };
  },
  computed: {
    ...mapGetters([
      "getLogo",
      "options",
      "getFooterPhone",
      "getFooterMenu",
      "getSocialLinks",
      "getFooterInfo",
    ]),
    btnBot() {
      return this.btnPos == "bot";
    },
    btnTop() {
      return this.btnPos == "top";
    },
  },
  methods: {
    toup() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
  },
};
