//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from "vuex";
import ButtonsTray from "../common/ButtonsTray.vue";
import StyledLink from "../common/StyledLink.vue";
export default {
  components: { StyledLink, ButtonsTray },
  name: "CardsLanding",
  computed: {
    blockClass() {
      switch (+this.propsData.title_column_select) {
        case 1:
          return "cell1";
        case 2:
          return "cell2";
        case 3:
          return "cell3";
        case 4:
          return "cell4";
        default:
          return "cell1";
      }
    },
  },
  methods: {
    ...mapActions(["setMenuState", "setMenuData", "setFormId"]),
    formOpen(data, id) {
      console.log(data, id);
      if (data) {
        console.log(data);
        this.setMenuState(true);
        this.setFormId(id);
        this.setMenuData(data);
      }
    },
  },
};
