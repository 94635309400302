//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { adapter } from "@/utils.js";
import ArticleBody from "../components/sections/ArticleBody.vue";
import SeeAlso from "../components/articleSections/SeeAlso.vue";
import AppBreadcrumbs from '../components/navigation/app-breadcrumbs.vue';
export default {
  components: { ArticleBody, SeeAlso, AppBreadcrumbs },
  name: "ArticlePage",
  data() {
    return {
      loaded: false,
      seeAlso: null,
      constructorList: null,
      model: null,
      translate: null,
      breadcrumbs: []
    };
  },
  created() {
    let url = "/api/blog/articles/get-by-slug";
    let props = {
      lang: this.$i18n.locale,
      slug: this.$route.params.slug,
    };
    adapter
      .post(url, props, this)
      .then((response) => {
        this.constructorList = response.constructor;
        this.model = response.model;
        this.breadcrumbs = response.breadcrumbs;
        this.translate = response.translate;
        this.seeAlso = response.see_also
        this.setMeta(this.meta);
        this.loaded = true;
      })
      .catch((e) => {
        this.handleError(e);
      });
  },
};
