//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { rtParams } from "@/utils.js";
export default {
  name: "LangSwitch",
  data() {
    return {
      active: false,
      currentLocale: "Укр",
      otherLocale: "eng",
    };
  },
  computed: {
    ...rtParams,
  },
  methods: {
    switchLocale(locale) {
      console.log(locale);
      if (this.currentLocale === locale) return;
      this.otherLocale = this.currentLocale;
      this.currentLocale = locale;
    },
  },
};
