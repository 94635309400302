//
//
//
//
//
//
//
//
//
//
//
//

import ButtonsTray from "../common/ButtonsTray.vue";
export default {
  components: { ButtonsTray },
  name: "SimpleTitle",
};
