import { render, staticRenderFns } from "./formEditor.vue?vue&type=template&id=21118680&scoped=true&"
import script from "./formEditor.vue?vue&type=script&lang=js&"
export * from "./formEditor.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21118680",
  null
  
)

export default component.exports