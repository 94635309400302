//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Slider from "../common/Slider.vue";
export default {
  components: { Slider },
  name: "CardsSlider",
  data() {
    return {
      flickityOptions: {
        cellAlign: "center",
        contain: true,
        pageDots: false,
        prevNextButtons: false,
        imagesLoaded: true,
        currentImg: 0,
      },
    };
  },
  methods: {
    next() {
      this.$refs.flickity.$refs.flickity.next(true, false);
    },
    prew() {
      this.$refs.flickity.$refs.flickity.previous(true, false);
    },
    imgLoaded() {
      this.$refs.flickity.$refs.flickity.resize();
    },
  },
};
