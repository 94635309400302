//
//
//
//
//
//
//
//
//
//
//
//
//
//

import YtModal from "../common/YtModal.vue";
export default {
	components: { YtModal },
	name: "VideoFrame",
	data() {
		return {
			modalShown: false,
			videoId: "Uf89FRDECFc",
		};
	},
};
