//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from "vuex";
// import ButtonsTray from "../common/ButtonsTray.vue";
import StyledLink from "../common/StyledLink.vue";
export default {
  name: "TextNextColBtn",
  components: {
    StyledLink,
    // ButtonsTray,
  },
  computed: {
    textSize() {
      return this.propsData.font_size;
    },
  },
  methods: {
    ...mapActions(["setMenuState", "setMenuData", "setFormId"]),
    formOpen(data, id) {
      if (data) {
        this.setMenuState(true);
        this.setFormId(id);
        this.setMenuData(data);
      }
    },
  },
};
