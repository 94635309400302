//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ButtonsTray from "../common/ButtonsTray.vue";
export default {
  components: { ButtonsTray },
  name: "ImageAndText",
  props: {},
  data() {
    return {};
  },
  computed: {
    imageFill() {
      return this.propsData.image_height;
    },
    textSize() {
      return this.propsData.font_size;
    },
    ratio() {
      switch (this.propsData.image_position) {
        case "left":
          if (this.propsData.column_width == 3) {
            return "imgLeft g1fr2fr";
          }
          if (this.propsData.column_width == 7) {
            return "imgLeft g2fr1fr";
          } else {
            return "imgLeft g1fr1fr";
          }
        case "right":
          if (this.propsData.column_width == 3) {
            return "g2fr1fr";
          }
          if (this.propsData.column_width == 7) {
            return "g1fr2fr";
          } else {
            return "g1fr1fr";
          }
      }
      return this.propsData.image_position;
    },
  },
};
