//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ButtonsTray from "../common/ButtonsTray.vue";
import Slider from "../common/Slider.vue";
export default {
  name: "ImageSlider",
  components: { ButtonsTray, Slider },
  data() {
    return {
      current: 0,
      //   flickityOptions: {
      //     cellAlign: "center",
      //     contain: true,
      //     pageDots: false,
      //     prevNextButtons: false,
      //     imagesLoaded: true,
      //     // wrapAround: true,
      //   },
      flickityOptions: {
        cellAlign: "center",
        contain: true,
        pageDots: false,
        prevNextButtons: false,
        adaptiveHeight: true,
        imagesLoaded: true,
        currentImg: 0,
      },
      modalGal: false,
    };
  },
  computed: {
    aligType() {
      return `align_${this.propsData.align}`;
    },
    noMouse() {
      return this.propsData.show_btns == "1";
    },
    isArticle() {
      return this.$route.name === "article";
    },
  },
  methods: {
    next() {
      this.$refs.flickity.$refs.flickity.next(true, false);
    },
    prew() {
      this.$refs.flickity.$refs.flickity.previous(true, false);
    },
    select(index) {
      this.$refs.flickity.$refs.flickity.select(index, true, false);
    },
    imgLoaded() {
      this.$refs.flickity.$refs.flickity.resize();
    },
  },
  mounted() {
    this.$refs.flickity.$refs.flickity.on("change", (index) => {
      this.current = index;
    });
    this.$refs.flickity.$refs.flickity.on(
      "staticClick",
      (event, pointer, cellElement, cellIndex) => {
        this.current = cellIndex + 1;
        // if (this.d.list[cellIndex].video) {
        // 	this.videoId = this.d.list[cellIndex].video.replace(
        // 		/(.*?)watch\?v=/,
        // 		""
        // 	);
        // 	this.modalGal = true;
        // }
      }
    );
  },
};
