//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ButtonsTray from "@/components/common/ButtonsTray.vue";
export default {
  components: { ButtonsTray },
  name: "Cta",
  computed: {
    bg() {
        return this.propsData.fill === "0" ? `background: ${this.propsData.bg_color}` : ''
    }
  },
};
