//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from "vuex";
export default {
  name: "TeamBlock",
  data() {
    return {
      isOpen: false,
      currentItem: null,
    };
  },
  computed: {
    ...mapGetters(["getBackground"]),
  },
  methods: {
    openModal(info) {
      this.currentItem = info;
      this.isOpen = true;
    },
  },
};
