import Vue from "vue";
import Vuex from "vuex";
import form from "./modules/form";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        options: null,
        headerMenu: null,
        footerMenu: null,
        windowSize: null
    },
    mutations: {
        updateSize(state, posts) {
            state.windowSize = posts
        },
        SETOPTIONS(state, payload) {
            state.options = payload
        },
        SETHEADERMENU(state, payload) {
            state.headerMenu = payload;
        },
        SETFOOTERMENU(state, payload) {
            state.footerMenu = payload;
        },
    },
    getters: {
        getSize(state) {
            return state.windowSize
        },
        getThemeConfig(state) {
            return {
                theme_bg_type: state.options?.theme_bg_type,
                theme_color: state.options?.theme_color,
                theme_font_style: state.options?.theme_font_style,
                theme_gradient: state.options?.theme_gradient,
                theme_gradient2: state.options?.theme_gradient2,
                theme_noise: state.options?.theme_noise,
                theme_gradient_deg: state.options?.theme_gradient_deg,
                theme_gradient_type: state.options?.theme_gradient_type,

            }
        },
        getBackground(state) {
            let bg = null
            if (state.options?.theme_bg_type === 'color') {
                bg = `background: ${state.options?.theme_color}`
            } else if (state.options?.theme_bg_type === 'gradient') {
                if (state.options?.theme_gradient_type === 'linear') {
                    bg = `background: linear-gradient(${state.options?.theme_gradient_deg}deg, ${state.options?.theme_gradient}, ${state.options?.theme_gradient2});`
                } else {
                    bg = `background: radial-gradient(circle, ${state.options?.theme_gradient}, ${state.options?.theme_gradient2});`
                }
            }
            return bg
        },
        options(state) {
            return state.options ? state.options : null;
        },
        getLogo(state) {
            return state.options?.logotype ? state.options.logotype : null;
        },
        getFooterMenu(state) {
            return state.footerMenu ? state.footerMenu : null;
        },
        getHeaderMenu(state) {
            return state.headerMenu ? state.headerMenu : null;
        },
        getHeaderPhone(state) {
            if (state.options.phones && state.options.phones.length > 0) {
                return state.options.phones[0].number;
            } else return null;
        },
        getFooterPhone(state) {
            if (state.options.phones && state.options.phones.length > 0) {
                return state.options.phones
            } else return null;
        },
        getSocialLinks(state) {
            let temp = []
            if (state.options && state.options.contacts && state.options.contacts.length > 0) {
                state.options.contacts.forEach(item => {
                    if (item.is_main && item.socials) {
                        temp.push(...item.socials)
                    }
                });
                return temp
            } else return null;
        },
        getFooterInfo(state) {
            let temp = [];
            if (state.options && state.options.contacts && state.options.contacts?.length > 0) {
                state.options.contacts.forEach(item => {
                    if (item.is_main) {
                        temp.push({
                            number: item.phones && item.phones.length > 0 ? item.phones[0].number : null,
                            address: item.address,
                            email: item.email
                        })
                    }
                });
                return temp
            } else return null;
        },
    },
    actions: {
        setCurrentSize({ commit }, windowSize) {
            if (windowSize.innerWidth >= 1200) {
                commit('updateSize', 'desktop')
            } else if (
                windowSize.innerWidth <= 1200 &&
                windowSize.innerWidth >= 769
            ) {
                commit('updateSize', 'tablet')
            } else {
                commit('updateSize', 'mobile')
            }
        },
    },
    modules: {
        form
    },
});
