//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ArticleBtnsTray from "@/components/articleSections/ArticleBtnsTray.vue";
import ArticleImage from "@/components/articleSections/ArticleImage.vue";
import ArticleImageAndText from "@/components/articleSections/ArticleImageAndText.vue";
import ArticleList from "@/components/articleSections/ArticleList.vue";
import ArticleSimpleText from "@/components/articleSections/ArticleSimpleText.vue";
import ArticleTop from "@/components/articleSections/ArticleTop.vue";
import TextButton from "@/components/articleSections/TextButton.vue";
import TextQuote from "@/components/articleSections/TextQuote.vue";
import VideoFrame from "@/components/articleSections/VideoFrame.vue";
import ImageSlider from "./ImageSlider.vue";
export default {
  name: "ArticleBody",
  components: {
    ArticleTop,
    VideoFrame,
    TextButton,
    quotes: TextQuote,
    "simple-text": ArticleSimpleText,
    "image-and-text": ArticleImageAndText,
    gallery: ImageSlider,
    button_component: ArticleBtnsTray,
    list: ArticleList,
    "image-blog": ArticleImage,
  },
  props: {
    model: {
      type: Object,
      default: () => {},
    },
    translate: {
      type: Object,
      default: () => {},
    },
    constructorList: {
      type: Array,
      default: () => [],
    },
  },
};
