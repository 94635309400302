//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  required,
  email,
  minLength,
  maxLength,
} from "vuelidate/lib/validators";
import { mapActions, mapGetters } from "vuex";
import formText from "./formText.vue";
import formInput from "./formInput.vue";
import formTitle from "./formTitle.vue";
import FormEditor from "./formEditor.vue";
import FormCheckbox from "./formCheckbox.vue";
import FormSelect from "./formSelect.vue";
export default {
  components: {
    formInput,
    formText,
    formTitle,
    FormEditor,
    FormCheckbox,
    FormSelect,
  },
  name: "block-form",
  data() {
    return {
      InputValues: {},
      showError: false,
      success: false,
      successText: null,
    };
  },
  computed: {
    ...mapGetters({
      getMenuData: "modules/form/getMenuData",
      getFormId: "modules/form/getFormId",
      getBackground: "getBackground",
    }),
    formTitle() {
      let temp = null;
      this.propsData.list.forEach((el) => {
        if (el.type === "form-title") temp = el;
      });
      return temp;
    },
  },
  validations() {
    const temp = {};
    temp.InputValues = {};
    this.propsData.list.forEach((el) => {
      if (
        +el.visibility &&
        el.type !== "form-title" &&
        el.type !== "form-text"
      ) {
        temp.InputValues[el.name] = {};
      }
      this.propsData.list.forEach((el) => {
        if (el.rules) {
          let rules = {};
          if (el.rules.required) {
            rules = { ...rules, ...{ required } };
          }
          if (el.rules.email) {
            rules = { ...rules, ...{ email } };
          }
          if (el.rules.min) {
            rules = { ...rules, ...{ minLength: minLength(el.rules.min) } };
          }
          if (el.rules.max) {
            rules = { ...rules, ...{ maxLength: maxLength(el.rules.max) } };
          }
          temp.InputValues[el.name] = {
            ...rules,
          };
        }
      });
    });

    return temp;
  },
  mounted() {
    if (this.propsData) {
      this.propsData.list.forEach((el) => {
        if (
          +el.visibility &&
          el.type !== "form-title" &&
          el.type !== "form-text"
        ) {
          this.$set(this.InputValues, el.name, "");
        }
      });
    }
  },
  methods: {
    ...mapActions({
      setMenuState: "modules/form/setMenuState",
    }),
    changeChekbox(name, data) {
      this[name] = data;
    },
    goHome() {
      this.setMenuState(false);
    },
    sendForm() {
      const option = Object.assign(this.InputValues, {
        form_id: this.propsData.form_id,
      });
      this.showError = true;
      console.log("$invalid = true");
      if (!this.$v.$invalid) {
        console.log("$invalid = false");
        this.axios.post("/api/request/send", option).then((res) => {
          this.success = true;
          this.successText = res.data;
          this.$refs.blockForm.reset();
          setTimeout(() => {
            this.success = false;
          }, 4000);
        });
      }
    },
  },
};
