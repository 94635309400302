import { render, staticRenderFns } from "./BlogArticleLinkV2.vue?vue&type=template&id=78bf586d&scoped=true&"
import script from "./BlogArticleLinkV2.vue?vue&type=script&lang=js&"
export * from "./BlogArticleLinkV2.vue?vue&type=script&lang=js&"
import style0 from "./BlogArticleLinkV2.vue?vue&type=style&index=0&id=78bf586d&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "78bf586d",
  null
  
)

export default component.exports