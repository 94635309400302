//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "pagination",
  props: {
    compdata: {
      type: [Array, Object],
      default: () => [],
    },
    currentPage: {
      type: Number,
      default: 1,
    },
  },
  computed: {
    btnPrev() {
      return this.currentPage - 1 > 0
        ? this.currentPage - 1
        : 'disabled'
    },
    btnNext() {
      return this.currentPage + 1 <= this.links.length
        ? this.currentPage + 1
        : "disabled";
    },
     links() {
      return this.compdata.filter((el) => {
        return +el.l
      })
    },
  },
  methods: {
    onPrew(s) {
      console.log(this.$route.query.page - 1);
      this.$router.push({
        name: this.$route.name,
        query: { page: this.$route.query.page - 1 },
      });
      if (s) this.$emit("click", "prev");
    },
    onNext(s) {
      if (s) this.$emit("click", "next");
    },
  },
};
