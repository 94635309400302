//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ButtonsTray from "../common/ButtonsTray.vue";
export default {
  components: { ButtonsTray },
  name: "FirstScreen",
  data() {
    return {};
  },
  computed: {
    classList() {
      return [
        this.propsData.widget_type,
        this.propsData.image && this.propsData.with_fon !== "0" ? "hasImg" : "",
      ];
    },
    bgImg() {
      if (this.propsData.with_fon !== "image") return null;
      if (window.innerWidth >= 576 && this.propsData.image) {
        return {
          backgroundImage: `url('${this.path(this.propsData.image)}')`,
        };
      } else if (window.innerWidth <= 576 && this.propsData.image_mob) {
        return {
          backgroundImage: `url('${this.path(this.propsData.image_mob)}')`,
        };
      } else {
        return null;
      }
    },
  },
};
