import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default ({
    state: {
        menuState: null,
        menuData: null,
        formId: null,
        formBtn: null,
    },
    mutations: {
        changeMenuState(state, data){
            state.menuState = data
        },
        changeMenuData(state, data){
            // debugger
            state.menuData = data
        },
        changeFormId(state, data){
            state.formId = data
        },
        changeFormBtn(state, data){
            state.formBtn = data
        }
    },
    actions: {
        setMenuState({commit}, data){
            commit('changeMenuState', data)
        },
        setMenuData({commit}, data){
            commit('changeMenuData', data)
        },
        setFormId({commit}, data){
            commit('changeFormId', data)
        },
        setFormBtn({commit}, data){
            commit('changeFormBtn', data)
        }
    },
    getters: {
        getMenuState(state) {
            return state.menuState ? state.menuState : null
        },
        getMenuData(state) {
            return state.menuData ? state.menuData : null
        },
        getFormId(state) {
            return state.formId ? state.formId : null
        },
        getFormBtn(state) {
            return state.formBtn ? state.formBtn : null
        }
    },
});
