import Vue from "vue";
import VueRouter from "vue-router";
import defaultLayout from "@/layouts/Default.vue";
import Landing from "@/views/Landing.vue";
import Article from "@/views/Article.vue";
import BlogPage from "@/views/BlogPage.vue";
import t from "@/components/CanvasBlur.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/t",
    name: "t",
    component: t,
  },
  {
    path: "",
    component: defaultLayout,
    children: [
      {
        path: "/:locale(sk|uk|en)?",
        name: "home",
        component: Landing,
      },
      {
        path: "/:locale(sk|uk|en)?/blog",
        name: "blog",
        component: BlogPage,
      },
      {
        path: "/:locale(sk|uk|en)?/blog/:slug",
        name: "rubric",
        component: BlogPage,
      },
      {
        path: "/:locale(sk|uk|en)?/blog/:rubric/:slug",
        name: "article",
        component: Article,
      },
      {
        path: "/:locale(sk|uk|en)?/:slug",
        name: "page",
        component: Landing,
      },
      {
        path: "/:locale(sk|uk|en)?/landing/:slug",
        name: "landing",
        component: Landing,
      },
      {
        path: "/:locale(sk|uk|en)?/404",
        name: "error",
        component: () =>
          import(/* webpackChunkName: "Error" */ "@/views/Error.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  // base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition || (to.name == "events" && from.name == "events"))
      return savedPosition;
    return {
      x: 0,
      y: 0,
    };
  },
  routes,
});

export default router;
