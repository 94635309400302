//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'
export default {
	name: "CanvasBlur",
	data() {
		return {
			cnv: null,
			ctx: null,
			w: null,
			h: null,
			radius: null,
			circles: [],
			speed: 0.003,
		};
	},
    computed: {
        ...mapGetters(["getThemeConfig", "getBackground"]),
    },
};
